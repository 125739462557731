.pagination {
    > li {
        margin-left: 0 !important;
        a, span {
            border: none;
            color: #50596c;
            font-size: 14px;
        }
        
        .first,
        .previous {
          > a,
          > span {
            margin-right: 2px;
            margin-right: 30px;  
          }
        }
        .next {
          > a,
          > span {
            margin-left: 30px;  
          }
        }
    }
    
    > li > a,
    > li > span {
        &:hover,
        &:focus {
            background-color: transparent;
            color: #ec1b30;
        }
    }
    
    li:before {
        display: none;
    }
    
    > .active > a,
    > .active > span {
    &,
    &:hover,
    &:focus {
            color: #fff;
            background-color: #005eac;
            border-radius: 2px;
            cursor: default;
            text-decoration: none;
            margin: 0 3px;
        }
    }
    
    .first,
    .last,
    .previous,
    .next {
        a{
            border: none;
            background: transparent;
            position: relative;
            top: 4px;
            padding: 1px 8px 4px;
            margin: 0 2px;


            span {
               font-size: 9px; 
                color: #6a6b6c;
            }
            &:hover {
                background: transparent;
                color: #ec1b30;

                span {
                  color: #ec1b30;  
                }        
            }
        }
    }
    
    .first {
        span {
            position: relative;
            right: 1px;
        }
    }
    .last { 
        span {
            position: relative;
            right: -1px;
        }
    }
}

@media all and (max-width: 650px) {
    
    .pagination {
        > li {
            a, span {
                    font-size: 16px;
                }
        }
        
        .first,
        .last,
        .previous,
        .next  {
            a {
                padding: 2px 8px 5px;
                span {
                   font-size: 13px; 
                } 
            }   
        }
    }
}
