/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin: 10px 0;
}
.breadcrumb li {
  position: relative;
  font-size: 13px;
  margin-right: 28px;
}
.pager li > a {
  border: none;
  color: #9da3ad;
  background-color: transparent;
  padding: 0;
}
.pager li > a:focus,
.pager li > a:hover {
  background-color: transparent;
  text-decoration: underline;
}
.breadcrumb > li + li:before {
  content: '\003e';
  position: absolute;
  color: #6b727f;
  padding: 0;
  left: -18px;
  top: 0;
}
.btn {
  border-radius: 3px;
  text-transform: uppercase;
  background-color: #fff;
  border: 2px solid #3c485f;
  color: #3c485f;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.btn.active,
.btn:active {
  box-shadow: none;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: inherit;
}
.btn-red {
  border: 2px solid #ec1b30;
  font-size: 13px;
  width: 210px;
  height: 51px;
  padding: 14px 0;
}
.btn-red:hover {
  background: #ed1b2f;
  color: #ffffff;
}
.btn-blue {
  background-color: #005eac;
  border-color: #005eac;
  color: #fff;
}
.btn-blue:hover {
  background-color: #0774ce;
  border-color: #0774ce;
  color: #fff;
}
.btn-white {
  border: 1px solid #d8dee4;
  font-size: 13px;
  text-transform: none;
}
.btn-white:hover {
  background-color: #eaeef1;
  color: #3c485f;
}
.btn-back {
  position: relative;
  padding: 10px 20px 10px 33px;
}
.btn-back:before {
  content: '';
  position: absolute;
  border: 4px solid transparent;
  border-right: 4px solid #5d677a;
  left: 10px;
  top: 15px;
}
.pagination > li {
  margin-left: 0 !important;
}
.pagination > li a,
.pagination > li span {
  border: none;
  color: #50596c;
  font-size: 14px;
}
.pagination > li .first > a,
.pagination > li .previous > a,
.pagination > li .first > span,
.pagination > li .previous > span {
  margin-right: 2px;
  margin-right: 30px;
}
.pagination > li .next > a,
.pagination > li .next > span {
  margin-left: 30px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: transparent;
  color: #ec1b30;
}
.pagination li:before {
  display: none;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: #fff;
  background-color: #005eac;
  border-radius: 2px;
  cursor: default;
  text-decoration: none;
  margin: 0 3px;
}
.pagination .first a,
.pagination .last a,
.pagination .previous a,
.pagination .next a {
  border: none;
  background: transparent;
  position: relative;
  top: 4px;
  padding: 1px 8px 4px;
  margin: 0 2px;
}
.pagination .first a span,
.pagination .last a span,
.pagination .previous a span,
.pagination .next a span {
  font-size: 9px;
  color: #6a6b6c;
}
.pagination .first a:hover,
.pagination .last a:hover,
.pagination .previous a:hover,
.pagination .next a:hover {
  background: transparent;
  color: #ec1b30;
}
.pagination .first a:hover span,
.pagination .last a:hover span,
.pagination .previous a:hover span,
.pagination .next a:hover span {
  color: #ec1b30;
}
.pagination .first span {
  position: relative;
  right: 1px;
}
.pagination .last span {
  position: relative;
  right: -1px;
}
@media all and (max-width: 650px) {
  .pagination > li a,
  .pagination > li span {
    font-size: 16px;
  }
  .pagination .first a,
  .pagination .last a,
  .pagination .previous a,
  .pagination .next a {
    padding: 2px 8px 5px;
  }
  .pagination .first a span,
  .pagination .last a span,
  .pagination .previous a span,
  .pagination .next a span {
    font-size: 13px;
  }
}
.table {
  background-color: #f0f3f6;
}
.table > thead > tr > th,
.table > thead > tr > td {
  line-height: 40px;
  vertical-align: middle;
}
.table > thead {
  background-color: #005eac;
}
.table > thead > tr > th {
  border-bottom: none;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table tbody:first-child th,
.table tbody:first-child td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table th.col-xs-1 {
  text-align: center;
}
a:hover,
a:active,
a:focus {
  text-decoration: none;
}
.equal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.border-box {
  box-sizing: border-box;
}
.modal.fade .modal-dialog {
  transform: none;
}
.modal-content {
  border: none;
  border-radius: 0;
}
.modal.in .modal-dialog {
  transform: none;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: inherit;
    margin: inherit;
  }
  .modal-content {
    box-shadow: none;
  }
}
.dropdown-menu {
  padding: 20px 0 24px;
  border: none;
  border-radius: inherit;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-box-shadow: 0 2px 24px rgba(108, 108, 108, 0.71);
  box-shadow: 0 2px 24px rgba(108, 108, 108, 0.71);
}
.dropdown-menu > li > a {
  font-size: 16px;
  padding: 7px 46px 7px 22px;
  line-height: 16px;
}
.dropdown-menu > li > a:hover {
  background-color: transparent;
  color: #005eac;
}
.carousel-control {
  top: inherit;
  left: inherit;
  bottom: 97px;
  width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 42px;
  color: #a9b0bb;
  text-shadow: none;
  opacity: 1;
  border-radius: 3px;
  transition: background-color 0.3;
  background-color: #dce1e7;
}
.carousel-control.left {
  left: 0;
  background-image: none;
}
.carousel-control.right {
  left: 47px;
  background-image: none;
}
.carousel-control:hover,
.carousel-control:focus {
  outline: 0;
  text-decoration: none;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #000;
}
.carousel-control:hover {
  color: #2a3548;
  background-color: #cbd2da;
}
.carousel-control .glyphicon-menu-left,
.carousel-control .glyphicon-menu-right {
  z-index: 4;
}
.carousel-indicators {
  display: none;
  bottom: 15px;
  z-index: 1;
}
.carousel-indicators li {
  width: 7px;
  height: 7px;
  margin: 1px 10px;
  border-radius: 8px;
  border: 1px solid #f0f2f5;
  cursor: pointer;
  background-color: #fff \9;
  background-color: #ffffff;
}
.carousel-indicators .active {
  margin: 0 10px;
  width: 9px;
  height: 9px;
  background-color: #005eac;
}
@media screen and (max-width: 600px) {
  .carousel-indicators {
    display: block;
  }
}
.nav-tabs {
  border-bottom: 1px solid #dee1e5;
  padding: 35px 0 0;
  right: 0 !important;
}
.nav-tabs > li {
  line-height: 30px;
}
.nav-tabs > li.active {
  border-bottom: 2px solid #005eac;
}
.nav-tabs > li > a {
  border: none;
  color: #414d58;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  padding: 0 12px;
}
.nav-tabs > li > a:hover {
  background: none !important;
  color: #414d58 !important;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background: none;
  border: none;
}
@media (max-width: 600px) {
  .nav-tabs > li {
    width: 45%;
    line-height: 35px;
  }
  .nav-tabs > li > a {
    font-size: 10px;
    vertical-align: top;
  }
}
.checkbox {
  padding-left: 20px;
  padding-right: 15px;
  margin-bottom: 20px;
}
.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 15px;
}
.checkbox label:hover {
  color: #005eac;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}
.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  content: '';
  width: 10px;
  height: 6px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 2px solid #0461ad;
  border-top: none;
  border-right: none;
  background: transparent;
  transform: rotate(-45deg);
}
.checkbox input[type="checkbox"]:indeterminate + label::after,
.checkbox input[type="radio"]:indeterminate + label::after {
  display: block;
  content: "";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px;
}
.checkbox input[type="checkbox"]:disabled,
.checkbox input[type="radio"]:disabled {
  cursor: not-allowed;
}
.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}
.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
}
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #fff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff;
}
.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #fff;
}
.checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
}
.checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.checkbox-info input[type="checkbox"]:indeterminate + label::before,
.checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:indeterminate + label::after,
.checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.checkbox-success input[type="checkbox"]:indeterminate + label::before,
.checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.checkbox-success input[type="checkbox"]:indeterminate + label::after,
.checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.radio {
  padding-left: 20px;
}
.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
  line-height: 22px !important;
}
.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 10px;
  height: 10px;
  left: 5px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #ed1b2f;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled {
  cursor: not-allowed;
}
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio.radio-inline {
  margin-top: 0;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #337ab7;
}
.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f;
}
.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
}
.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e;
}
.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c;
}
input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  content: '';
  width: 8px;
  height: 4px;
  position: absolute;
  top: 3px;
  left: 3px;
  border: 2px solid #0461ad;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
}
input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
  color: #fff;
}
input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
  color: #fff;
}
.form-control {
  border-radius: 2px;
  height: 40px;
  box-shadow: none;
}
.form-control:focus {
  border-color: #005eac;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);
}
.nav > li {
  display: table-cell;
}
.nav > li a {
  display: inherit;
}
.nav > li a:hover {
  background-color: #ff4052;
}
/*# sourceMappingURL=bootstrap-theme.css.map */