.table {
    background-color: #f0f3f6;
     
  > thead {
    > tr {
      > th,
      > td {
        line-height: 40px;
        vertical-align: middle;
      }
    }
  }
  // Bottom align for column headings
  > thead {
    background-color: #005eac;
  }
  > thead > tr > th {
    border-bottom: none;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  tbody:first-child {
      th, td {
        border-top: 0;
      }
  }
 
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid @table-border-color;
  }
  
    th.col-xs-1 {
        text-align: center
    }  
}