
.btn {
    border-radius: 3px;
    text-transform: uppercase;
    background-color: #fff;
    border: 2px solid #3c485f;
    color: #3c485f;
    font-weight: 600;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.btn.active, .btn:active {
    box-shadow: none;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: inherit;
}

.btn-red {
    border: 2px solid #ec1b30;
    font-size: 13px;
    width: 210px;
    height: 51px;
    padding: 14px 0;
    
    &:hover {
        background: #ed1b2f;
        color: #ffffff;
    }
}

.btn-blue {
    background-color: #005eac;
    border-color: #005eac;
    color: #fff;
    
    &:hover {
        background-color: #0774ce;
        border-color: #0774ce;
        color: #fff;
    }
}

.btn-white {
    border: 1px solid #d8dee4;
    font-size: 13px;
    text-transform: none;
    
    &:hover {
        background-color: #eaeef1;
        color: #3c485f;
    }
}

.btn-back {
        position: relative;
        padding: 10px 20px 10px 33px;
        
        &:before {
            content: '';
            position: absolute;
            border: 4px solid transparent;
            border-right: 4px solid #5d677a;
            left: 10px;
            top: 15px;
        }
    }