.breadcrumb {
    background-color: transparent;
    padding: 0;
    margin: 10px 0;
    
    li {
        position: relative;
        font-size: 13px;
        margin-right: 28px;
    }
}

.pager li>a {
    border: none;
    color: #9da3ad;
    background-color: transparent;
    padding: 0;
    
    &:focus, &:hover {
        background-color: transparent;
        text-decoration: underline;
    }
}

.breadcrumb>li+li:before {
    content: '\003e';
    position: absolute;
    color: #6b727f;
    padding: 0;
    left: -18px;
    top: 0;
}